'use client';

import { Button, cn, useDisclosure } from '@nextui-org/react';
import { AlertTriangle } from 'lucide-react'; // Importar ícono de Lucide

import { useEffect } from 'react';
import Link from 'next/link';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { useSelectedProjectStore } from '@/src/lib/stores/selected-project-store/use-selected-project.store';
import UserFeedbackDialog from '@/components/shared/client/feedback/user-feed-back-dialog';
import useLocalStorageTheme from '@/lib/hooks/theme/local-storage-theme';
export default function MainErrorComponent() {
  const modalState = useDisclosure();
  const selectedProject = useSelectedProjectStore(state => state.selectedProject);
  useEffect(() => {
    if (!modalState.isOpen) {
      modalState.onOpen();
    }
  }, [modalState]);
  const {
    theme
  } = useLocalStorageTheme();
  if (!theme) {
    return null;
  }
  return <NextThemesProvider attribute={'class'} defaultTheme={'dark'} enableColorScheme={true} data-sentry-element="NextThemesProvider" data-sentry-component="MainErrorComponent" data-sentry-source-file="main-error-component.tsx">
      <div className={cn('flex h-screen w-full flex-col items-center justify-center space-y-6 p-5 text-center', {
      'bg-black text-white': theme === 'dark',
      'bg-gray-50 text-black': theme === 'light'
    })}>
        <AlertTriangle className="text-red-500" size={64} data-sentry-element="AlertTriangle" data-sentry-source-file="main-error-component.tsx" /> {/* Ícono de advertencia */}
        <h2 className="text-4xl font-extrabold text-red-500">¡Ups! Algo salió mal</h2>
        <p className="text-lg">
          Parece que ha ocurrido un error inesperado. Por favor, inténtalo de nuevo o contacta al soporte si el problema persiste.
        </p>
        <div className="flex flex-wrap items-center justify-center gap-x-4">
          <Button className="transition-shadow duration-300 hover:shadow-lg" color="primary" onClick={() => {
          modalState.onOpen();
        }} data-sentry-element="Button" data-sentry-source-file="main-error-component.tsx">
            Reportar
          </Button>
          <Button as={Link} className="transition-shadow duration-300 hover:shadow-lg" color="primary" href={`/${selectedProject?.id}/dashboard/kpis/costs`} variant="flat" data-sentry-element="Button" data-sentry-source-file="main-error-component.tsx">
            Ir al inicio
          </Button>
        </div>
        <UserFeedbackDialog modalState={modalState} shouldUseReplay={false} data-sentry-element="UserFeedbackDialog" data-sentry-source-file="main-error-component.tsx" />
      </div>
    </NextThemesProvider>;
}