'use client';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UserAuthenticateAssignProject } from '@/lib/types/api/user/user';
export enum SidebarModes {
  HIDDEN = 0,
  OPEN = 1,
}
export enum ReportIssueMode {
  CLOSED = 1,
  OPEN = 0,
}
interface UserSettings {
  reportAdvanceProductionElements: string[];
  reportIssueButtonmode: ReportIssueMode;
}
type UserInfoStore = {
  addNewProject: (project: Partial<UserAuthenticateAssignProject>) => void;
  assignedProjects: Partial<UserAuthenticateAssignProject>[];
  clearAssignedProjects: () => void;
  setAssignedProjects: (projects: Partial<UserAuthenticateAssignProject>[]) => void;
  updateUserSettings: (settings: Partial<UserSettings>) => void;
  userSettings: UserSettings;
};
export const useUserInfoStore = create<UserInfoStore>()(persist((set, get) => ({
  addNewProject: project => {
    set({
      assignedProjects: [...get().assignedProjects, project]
    });
  },
  assignedProjects: [],
  clearAssignedProjects: () => {
    set({
      assignedProjects: []
    });
  },
  setAssignedProjects: projects => set({
    assignedProjects: projects
  }),
  updateUserSettings(settings) {
    set({
      userSettings: {
        ...get().userSettings,
        ...settings
      }
    });
  },
  userSettings: {
    reportAdvanceProductionElements: [],
    reportIssueButtonmode: ReportIssueMode.OPEN
  }
}), {
  name: 'user-info-store'
}));