'use client';

import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Input, Textarea, useDisclosure } from '@nextui-org/react';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import * as Sentry from '@sentry/nextjs';
import { useFormik } from 'formik';
import { Trash } from 'lucide-react';
import { Session } from 'next-auth';
import { toast } from 'sonner';
import * as Yup from 'yup';
import { useSelectedProjectStore } from '@/src/lib/stores/selected-project-store/use-selected-project.store';
import { getCurrentUserSession } from '@/lib/utils/functions/auth/get-current-session';
interface UserFeedbackDialogProps {
  modalState: ReturnType<typeof useDisclosure>;
  shouldUseReplay?: boolean;
}
const schema = Yup.object({
  email: Yup.string().email('Email invalido').required('Email requerido'),
  message: Yup.string().required('Mensaje requerido'),
  name: Yup.string().required('Nombre requerido')
});
type SentryAttachmments = Sentry.EventHint['attachments'];
export default function UserFeedbackDialog({
  modalState,
  shouldUseReplay
}: UserFeedbackDialogProps) {
  const selectedProject = useSelectedProjectStore(state => state.selectedProject);
  const [attachments, setAttachments] = useState<SentryAttachmments>([]);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [session, setSession] = useState<Session | null>(null);
  const loadSession = async () => {
    const sessionResult = await getCurrentUserSession();
    setSession(sessionResult);
    form.setValues({
      email: sessionResult?.user?.email,
      message: 'Quiero reportar un problema',
      name: sessionResult?.user?.name
    } as any);
    form.validateField('email');
    form.validateField('message');
    form.validateField('name');
  };
  useEffect(() => {
    loadSession();
  }, []);
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newAttachments = await Promise.all(Array.from(files).map(async file => {
        const data = new Uint8Array(await file.arrayBuffer());
        return {
          contentType: file.type || 'application/octet-stream',
          data,
          filename: file.name
        };
      }));
      setAttachments((prev: any) => [...prev, ...newAttachments]);
    }
  };
  const removeAttachment = (index: number) => {
    setAttachments(prev => prev?.filter((_, i) => i !== index));
  };
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const newAttachments = await Promise.all(acceptedFiles.map(async file => {
      const data = new Uint8Array(await file.arrayBuffer());
      return {
        contentType: file.type || 'application/octet-stream',
        data,
        filename: file.name
      };
    }));
    setAttachments((prev: any) => [...prev, ...newAttachments]);
  }, []);
  const {
    getInputProps,
    getRootProps,
    isDragActive
  } = useDropzone({
    accept: {
      'application/pdf': [],
      'image/*': []
    },
    multiple: true,
    onDrop
  });
  const sendFeedback = () => {
    Sentry.sendFeedback({
      email: form.values.email,
      message: `${form.values.message}\n\nProyecto: ${selectedProject?.name}`,
      name: form.values.name
    }, {
      attachments: attachments as Sentry.EventHint['attachments'],
      includeReplay: shouldUseReplay
    });
    modalState.onClose();
    form.resetForm();
    form.setSubmitting(false);
    setAttachments([]);
    toast.success('¡Gracias por tu reporte! Nos pondremos en contacto contigo lo antes posible.');
  };
  const form = useFormik({
    initialValues: {
      email: '',
      message: '',
      name: ''
    },
    onSubmit: sendFeedback,
    validateOnMount: true,
    validationSchema: schema
  });
  return <Modal placement="center" scrollBehavior="inside" size="xl" {...modalState} data-sentry-element="Modal" data-sentry-component="UserFeedbackDialog" data-sentry-source-file="user-feed-back-dialog.tsx">
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="user-feed-back-dialog.tsx">
        <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="user-feed-back-dialog.tsx">Reportar un problema</ModalHeader>
        <ModalBody className="scrollbar-thin" data-sentry-element="ModalBody" data-sentry-source-file="user-feed-back-dialog.tsx">
          <div className="flex flex-col gap-y-4">
            <Input errorMessage={form.errors.name} isDisabled={form.isSubmitting} isInvalid={!!form.errors.name} isRequired label="Nombre" maxLength={128} name="name" onBlur={form.handleBlur} onChange={form.handleChange} placeholder="Nombre" type="text" value={form.values.name} data-sentry-element="Input" data-sentry-source-file="user-feed-back-dialog.tsx" />
            <Input errorMessage={form.errors.email} isDisabled={form.isSubmitting} isInvalid={!!form.errors.email} isRequired label="Email" maxLength={128} name="email" onBlur={form.handleBlur} onChange={form.handleChange} placeholder="Email" type="email" value={form.values.email} data-sentry-element="Input" data-sentry-source-file="user-feed-back-dialog.tsx" />
            <Textarea errorMessage={form.errors.message} isDisabled={form.isSubmitting} isInvalid={!!form.errors.message} isRequired label="Mensaje" maxLength={512} name="message" onBlur={form.handleBlur} onChange={form.handleChange} placeholder="Mensaje" value={form.values.message} data-sentry-element="Textarea" data-sentry-source-file="user-feed-back-dialog.tsx" />
            <Input className="hidden" multiple onChange={handleFileChange} ref={inputRef} type="file" data-sentry-element="Input" data-sentry-source-file="user-feed-back-dialog.tsx" />

            <div {...getRootProps()} className="text-md cursor-pointer rounded-lg bg-[#F3F3F4] p-4 text-[#484848] dark:bg-[#27272A] dark:text-white">
              <input {...getInputProps()} />
              <p className="text-center">{isDragActive ? 'Suelta los archivos aquí' : 'Arrastra y suelta los archivos aquí'}</p>
              <p className="text-center">Formatos: PDF, Imagen</p>
            </div>

            <div className="max-h-[150px] overflow-auto p-2 scrollbar-thin">
              <div className="flex flex-col gap-y-4">
                {attachments?.map((attachment, index) => {
                return <div className="flex w-full items-center justify-between gap-x-5 rounded-md  bg-[#F3F3F4] p-2 text-[#484848] dark:bg-[#27272A] dark:text-white" key={`${attachment?.filename}`}>
                      <div className="line-clamp-1">{attachment.filename}</div>
                      <Trash className="shrink-0 cursor-pointer text-red-500" onClick={() => removeAttachment(index)} />
                    </div>;
              })}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="user-feed-back-dialog.tsx">
          <Button color="danger" onClick={modalState.onClose} data-sentry-element="Button" data-sentry-source-file="user-feed-back-dialog.tsx">
            Cancelar
          </Button>
          <Button color="primary" disabled={form.isSubmitting || !form.isValid} onClick={() => {
          form.handleSubmit();
        }} data-sentry-element="Button" data-sentry-source-file="user-feed-back-dialog.tsx">
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>;
}